<template src="./modal.html"></template>
<style scoped src="./modal.css"></style>
<script>
export default {
    data () {
        return {
            id: 'modalId',
        }
    },
    computed : {
        alertObject () {
            return this.$parent.alertObject
        },
        modalBackground () {
            return this.alertObject.errorCode === 1 ? 'primary' : 'danger'
        }
    }
}
</script>
