<template src="./fxtransaction.html"></template>
<style scoped src="./fxtransaction.css"></style>
<script>
import Loading from 'vue-loading-overlay'
import fxService from '@/services/FXServices.vue'
import accService from '@/services/AccountService.vue'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            fxTransactionId: null,
            fxTransactionParentId: '',
            loaderVisible : false,
            resData : {},
            fxAccountList : [],
            currentPage: 1,
            perPage : 8,
            notes: '',
            Utils : Utils,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            fields: [
                {key: 'CreateDateUtc', label: 'Created Date'},
                {key: 'CreatedBy', label: 'Created by'},
                {key: 'Note', label: 'Note' }
            ]
        }
    },
    components : {
        Loading, modal
    },
    methods : {
        removeUnderscoreTextNotes(fxTransactionNotes) {
            let noteData = fxTransactionNotes.csFXTransactionNote
            if(noteData) {
                noteData = Array.isArray(noteData) ? noteData : [noteData]
                noteData.forEach(noteItem => {
                    Object.keys(noteItem).map(key =>{
                        noteItem[key] = noteItem[key]._text ? noteItem[key]._text : null
                        if(key === 'CreateDateUtc')
                            noteItem[key] = noteItem[key] ? Utils.methods.UTCtoLocal(moment(noteItem[key], 'MM/DD/YYYY HH:mm:ss A').format('MM/DD/YYYY HH:mm:ss')) : null
                    }) 

                })
            }else{
                noteData = []
            }
            return noteData
        },
        async handleAddNotesEvent() {
            try {
                this.loaderVisible = true
                await fxService.methods.AddFxTransactionNote({
                    FXTransacationId : this.fxTransactionId,
                    NoteText : this.notes
                })
                .then(async () => {
                    await this.refreshNotesTable()
                    this.loaderVisible = false
                    this.showAlert(1, 'Notes Added Successfully')
                })
                .catch(err =>{
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
                this.loaderVisible = false
            } catch (error) {
                //
            }
        },
        async refreshNotesTable () {
            await fxService.methods.GetFXTransactionSummary({
                    FXTransacationId : this.fxTransactionId,
                    FxTransctionParentId : this.fxTransactionParentId,
                })
                .then(res => { 
                    let fxTransactionSummary = res.csGetFXTransactionSummaryResponse.FXTransactionSummary.csGetFXTransactionSummaryResponseItem
                    if(fxTransactionSummary) {
                        this.resData.FXTransactionNotes = this.removeUnderscoreTextNotes (fxTransactionSummary.FXTransactionNotes)
                    }
                })
                .catch(err =>{
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
        },
        delay(n) {
            return new Promise(function(resolve){
                setTimeout(resolve,n*1000);
            });
        },
        async removeFxTransaction() {
            try {
                this.loaderVisible = true
                await accService.methods.GetAccountDetails(this.resData.ExecutedBy)
                .then(res => {
                    let userIdNumber = res.csGetUserProfileResponse.UserIdNumber._text ? res.csGetUserProfileResponse.UserIdNumber._text : null
                    fxService.methods.CancelFxTransaction({
                        ExecutedBy : userIdNumber,
                        FXTransactionId : this.fxTransactionId
                    })
                    .then( () => this.showAlert(1, 'FX Transaction Removed Successfully'))
                    .catch(err => {
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
                })
                .catch(err =>{
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
                await this.delay(1)
                this.goBack()
            } catch (error) {
                this.loaderVisible = false
            }
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        goBack () {
            this.$router.push({
                name : 'FXTrasactionSummary',
                params: {
                    fxTransactionSummaryFilterParams: this.$route.params.fxTransactionSummaryFilterParams
                }
            })
        }
    },
    computed : {
        ...mapGetters(['getFXTransactionSummary', 'getSelectedFXTransaction']),
      rows () { return this.resData.FXTransactionNotes?.length }
    },
    created () {
        this.fxTransactionId = this.$route.params.fxtransactionid
        this.fxTransactionParentId = this.$route.params.fxTransactionParentId
        this.$store.commit('storeFxTransactionData', {
            fxTransactionId: this.$route.params.fxtransactionid,
            fxTransactionParentId: this.$route.params.fxTransactionParentId
        })
        let fxTransactionSummary = this.getSelectedFXTransaction
        Object.keys(fxTransactionSummary).map(key => {
            if(key === 'FXTransactionNotes') fxTransactionSummary[key] = this.removeUnderscoreTextNotes(fxTransactionSummary[key])
            else fxTransactionSummary[key] = fxTransactionSummary[key]._text ? fxTransactionSummary[key]._text : null

            if(key === 'TransactionTime') fxTransactionSummary[key] = fxTransactionSummary[key] ? Utils.methods.UTCtoLocal(moment(moment(fxTransactionSummary[key], 'MM/DD/YYYY hh:mm:ss A')._d).format('MM-DD-YYYY HH:mm:ss')) : null
        })
        this.resData = fxTransactionSummary
        this.resData.SourceFXAccountId = this.$route.params.SourceFXAccountId ?? null
        this.resData.DestinationFXAccountId = this.$route.params.DestinationFXAccountId ?? null
    },
}
</script>